import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
  charts: {},
};

const visaSlice = createSlice({
  name: "visa",
  initialState,
  reducers: {
    loading(state) {
      state.loading = true;
      state.error = null;
    },
    error(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setData(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    setChartData(state, action) {
      state.loading = false;
      const charts = {};
      action.payload.forEach((e, i) => {
        charts[e?.Category] = charts[e?.Category]?.length
          ? [
              ...charts[e?.Category],
              { label: e?.Value || "Unspecified", value: e?.Count, id: i },
            ]
          : [{ label: e?.Value || "Unspecified", value: e?.Count, id: i }];
      });
      state.charts = charts;
    },
  },
});

export const { loading, error, setData, setChartData } = visaSlice.actions;

export default visaSlice.reducer;
