import { configureStore } from "@reduxjs/toolkit";
// import thunkMiddleware from "redux-thunk";
import loginReducer from "./Slices/Login";
import medicalTreatment from "./Slices/MedicalTreatment";
import plans from "./Slices/Plans";
import visa from "./Slices/Visa";

const store = configureStore({
  reducer: {
    login: loginReducer,
    medicalTreatment,
    plans,
    visa,
  },
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(thunkMiddleware),
  // devTools: process.env.NODE_ENV === "production" ? false : true,
});

export default store;
