import { BrowserRouter } from "react-router-dom";
import "./Theme/fonts.css";
import getTheme from "./Theme/index";
import Loader from "./Components/Loader";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import { Suspense, useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Box, CssBaseline } from "@mui/material";
import { Provider } from "react-redux";
import store from "./Store";
import Router from "./Router";

function App() {
  const { i18n } = useTranslation();
  const isRTL = i18n.language === "ar" ? "rtl" : "ltr";
  moment.locale(i18n.language);
  document.body.setAttribute("dir", isRTL);

  const theme = useMemo(() => {
    return createTheme({ ...getTheme(isRTL), direction: isRTL });
  }, [isRTL]);

  let plugins = [prefixer];
  if (isRTL === "rtl") {
    plugins.push(rtlPlugin);
  }

  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: plugins,
  });

  return (
    <Suspense fallback={<Loader />}>
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Box id="app">
            <Suspense fallback={<Loader />}>
              <Provider store={store}>
                <BrowserRouter>
                  <Router />
                </BrowserRouter>
              </Provider>
            </Suspense>
          </Box>
        </ThemeProvider>
      </CacheProvider>
    </Suspense>
  );
}

export default App;
