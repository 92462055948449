import { Suspense, lazy, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
// import Navbar from "../Layout/Navbar";
import PageLoader from "../Components/Loader";

// const Login = lazy(() => import("../Pages/login/LoginForm"));
// const Registration = lazy(() =>
//   import("../Pages/registration/RegistrationForm")
// );
const Dashboard = lazy(() => import("../Pages/dashboard/index"));

export const Router = () => {
  const location = useLocation();

  const scrollToTop = (url) => {
    if (!url.includes("#")) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToTop(window.location.href);
  }, [location]);

  return (
    <>
      {/* <Navbar /> */}
      <Routes>
        {/* <Route
          path="/"
          element={
            <Suspense fallback={<PageLoader />}>
              <Login />
            </Suspense>
          }
        />

        <Route
          path="/register"
          element={
            <Suspense fallback={<PageLoader />}>
              <Registration />
            </Suspense>
          }
        /> */}

        <Route
          path="/"
          element={
            <Suspense fallback={<PageLoader />}>
              <Dashboard />
            </Suspense>
          }
        />

        <Route path="404" element={<>Not Found</>} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </>
  );
};

export default Router;
